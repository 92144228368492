<template>
  <div class="loader-task">
    <div class="loader-task__top">
      <div class="loader-task__text"><span></span><em></em><i></i></div>
      <div class="loader-task__text"><b></b><i></i><span></span></div>
      <div class="loader-task__text"><em></em><b></b><i></i></div>
    </div>
    <div class="loader-task__bottom">
      <div class="loader-task__img"></div>
      <div class="loader-task__name"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderTask',
}
</script>

<style lang="scss" scoped>
@keyframes loading {
  0% {
    transform: translateX(-100%) skew(-20deg)
  }
  100% {
    transform: translateX(290px) skew(-20deg)
  }
}

.loader-task {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  background: #fdfdfd;
  border-bottom: 1px solid #f9f9f9;
  border-radius: 5px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 130px;
    opacity: .5;
    background: linear-gradient(to right, transparent, #fff, transparent);
    animation: loading 2s cubic-bezier(0.55, 0.04, 0.04, 0.96) infinite;
  }

  .dark-theme & {
    background: $darkThemeBgOne;
    border-bottom: 1px solid $darkThemeBgThree;

    &:before {
      background: linear-gradient(to right, transparent, $darkThemeBgOne, transparent);
      animation: loading 2s cubic-bezier(0.55, 0.04, 0.04, 0.96) infinite;
    }

    &__img {
      background: $darkThemeBgTwo;
    }

    &__name {
      background: $darkThemeBgTwo;
    }

    &__text {
      span, i, em, b {
        background: $darkThemeBgTwo;
      }
    }
  }

  &__top {
    flex: 1 1 auto;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
  }

  &__img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #f9f9f9;
  }

  &__name {
    height: 10px;
    width: 100px;
    margin-left: 10px;
    background: #f9f9f9;
    border-radius: 5px;
  }

  &__text {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
    margin: 0 -5px;

    span, i, em, b {
      display: block;
      background: #f9f9f9;
      height: 10px;
      border-radius: 5px;
      margin: 0 5px;
    }

    b {
      flex: 1 1 20%;
    }

    span {
      flex: 1 1 30%;
    }

    em {
      flex: 1 1 45%
    }

    i {
      flex: 1 1 10%;
    }
  }
}
</style>
